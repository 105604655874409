import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { SessionManager } from '../services/auth/session-manager/session-manager.service'
import { SessionData } from '../models/auth/session-data.model'

@Injectable({
  providedIn: 'root',
})
export class SessionGuard {
  public session!: SessionData

  constructor(private sessionManager: SessionManager, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.sessionManager.session$.subscribe((session) => {
      this.session = session
    })

    if (this.session.logged) {
      const permissions = rolePermissions.find((role) => role.name == this.session.data?.role)
      if (!!permissions) {
        const currentPath = route.routeConfig?.path || ''
        if (permissions.path_blocked.includes(currentPath)) {
          console.log('No tienes permisos para acceder a esta vista')
          this.router.navigate([permissions.path_default])
          return false
        } else {
          return true
        }
      } else {
        console.log('No hay ningun rol con la siguiente descripción: ' + this.session.data?.role)
        this.router.navigate(['/auth/login'])
        return false
      }
    } else {
      console.log('No estas loggeado, redirigiendo...')
      this.router.navigate(['/auth/login'])
      return false
    }
  }
}

const rolePermissions: { name: string; path_blocked: string[]; path_default: string }[] = [
  { name: 'Superadmin', path_blocked: [''], path_default: '/applicant' },
  { name: 'Examinador Teórico', path_blocked: ['exam-administrator', 'users'], path_default: '/applicant' },
  {
    name: 'Director/a de tránsito',
    path_blocked: ['exam-administrator', 'applicant', 'users'],
    path_default: '/examination-history',
  },
]
