import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SessionGuard } from './core/guards/session.guard'

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules').then((m) => m.AuthModule),
  },
  {
    path: 'applicant',
    canActivate: [SessionGuard],
    loadChildren: () => import('./modules').then((m) => m.ApplicantModule),
  },
  {
    path: 'examination-history',
    canActivate: [SessionGuard],
    loadChildren: () => import('./modules').then((m) => m.ExaminationHistoryModule),
  },
  {
    path: 'exam-administrator',
    canActivate: [SessionGuard],
    loadChildren: () => import('./modules').then((m) => m.ExamAdministratorModule),
  },
  {
    path: 'users',
    canActivate: [SessionGuard],
    loadChildren: () => import('./modules').then((m) => m.UsersModule),
  },
  {
    path: 'report',
    canActivate: [SessionGuard],
    loadChildren: () => import('./modules').then((m) => m.DashboardModule),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
