import { Component, OnInit } from '@angular/core'
import { SessionManager } from './core/services/auth/session-manager/session-manager.service'
import { environment } from '../environments/environments'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Conaset Nexteo'

  constructor(private sessionManager: SessionManager) {}

  ngOnInit(): void {
    // console.log(environment.claveunica)
  }
}
