export const environment = {
  env: 'dev',
  api_url: 'https://api.nexteo-dev.conaset.cl',
  claveunica: {
    auth_api: 'https://api.nexteo-dev.conaset.cl',
    redirect_login: 'https://accounts.claveunica.gob.cl',
    claveunica_client_id: 'e5fc981995594f608340bb58c5d6ed29',
    claveunica_login_uri: 'https://nexteo-dev.conaset.cl/auth/callback',
    claveunica_logout_uri: 'https://nexteo-dev.conaset.cl/auth/logout',
  },
}
