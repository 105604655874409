import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { environment } from '../../../../../environments/environments'
import { NexteoService } from '../nexteo/nexteo.service'
import { UserLogged } from 'src/app/core/models/auth/session-data.model'

@Injectable({
  providedIn: 'root',
})
export class ClaveunicaLoginService {
  protected client_id = 'client_id=' + environment.claveunica.claveunica_client_id
  protected redirect_uri = environment.claveunica.claveunica_login_uri
  protected response_type = 'response_type=code'
  protected scope = 'scope=openid run name'

  constructor(private http: HttpClient, private nexteoService: NexteoService) {}

  redirect(): void {
    const url = environment.claveunica.redirect_login + '/openid/authorize'
    const state = 'state=' + this.generateHash(30)
    const redirect =
      url +
      `?${this.client_id}&${this.response_type}&${this.scope}&redirect_uri=${encodeURIComponent(this.redirect_uri)}&${state}`
    window.location.href = redirect
  }

  async login(accessCode: string, accessState: string) {
    const url = environment.claveunica.auth_api + '/login/clave-unica/token'
    //const token = await this.nexteoService.getToken(environment.cognito)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    const body = {
      code: accessCode,
      state: accessState,
      env: environment.env,
    }

    return this.http.post<UserLogged>(url, body, { headers })
  }

  async refresh(token: string) {
    const url = environment.claveunica.auth_api + '/login/clave-unica/token/refresh'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    const body = {
      token: token,
      env: environment.env,
    }

    return this.http.post<UserLogged>(url, body, { headers })
  }

  claveUnicaLogout() {
    const url = environment.claveunica.redirect_login + '/api/v1/accounts/app/logout'
    const redirect_uri = environment.claveunica.claveunica_logout_uri
    const redirect =
      url + `?${this.client_id}&${this.response_type}&${this.scope}&redirect_uri=${encodeURIComponent(redirect_uri)}`
    window.location.href = redirect
  }

  private generateHash(length: number): string {
    let hash = ''
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length)
      hash += chars.charAt(randomIndex)
    }
    return hash
  }
}
