import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import queryString from 'query-string'

@Injectable({
  providedIn: 'root',
})
export class NexteoService {
  constructor(private http: HttpClient) {}

  async getToken(env: any): Promise<string> {
    const url = env.auth_api + '/oauth2/token'

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })

    const body = {
      scope: env.scope,
      client_id: env.client_id,
      client_secret: env.client_secret,
      grant_type: env.grant_type,
    }

    return new Promise((resolve, reject) => {
      this.http.post(url, queryString.stringify(body), { headers }).subscribe({
        next: (response: any) => {
          if (response && response.access_token) {
            resolve(response.access_token)
          } else {
            reject(new Error('Access Token not found'))
          }
        },
        error: (error) => {
          console.error('Error:', error)
          reject(error)
        },
      })
    })
  }
}
