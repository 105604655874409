import { ExaminerMunicipality } from '../others/municipality-list.model'

export interface SessionData {
  logged?: boolean
  data: UserLogged
}

interface UserResponseBody {
  run: number
  dv: string
  name: string
  last_name: string
  photo: string
  examiner_municipalities: ExaminerMunicipality[]
}

export class UserLoggedResponse {
  token!: Tokens
  body!: UserResponseBody
}

export class UserLogged {
  token!: Tokens
  run!: number
  dv!: string
  name!: string
  last_name!: string
  municipalities!: ExaminerMunicipality[]
  photo?: string
  role_permissions?: string[]
  role?: string
  name_municipality?: string
  id_municipality?: number
  time?: any
}

export class Tokens {
  token_clave_unica?: string
  token_sgl?: string
  token_nexteo?: string
}

export const emptyUser: any = {
  logged: false,
}

/*
export class UserLoggedResponse {
  token!: Token;
  body!: {
    run: number
    dv: string
    name: string
    last_name: string
    photo: string
    examiner_municipalities: ExaminerMunicipality
  }
}

export class UserLogged {
  token!: Token;
  run!: number
  dv!: string
  name!: string
  last_name!: string
  photo?: string
  role_permissions?: string[]
  role?: string 
  name_municipality?: string;
  id_municipality?: number;
}
*/
