import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

import { SessionData, UserLogged, UserLoggedResponse, emptyUser } from 'src/app/core/models/auth/session-data.model'
import { ClaveunicaLoginService } from '../claveunica/clave-unica-login.service'
import { NexteoService } from '../nexteo/nexteo.service'
import moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class SessionManager {
  private sessionSubject = new BehaviorSubject<SessionData>(emptyUser)
  session$ = this.sessionSubject.asObservable()
  sessionData!: SessionData
  userLogged: UserLogged | undefined

  timeReviewSession: number = 60 // segundos
  accessCode: string = ''
  accessState: string = ''
  instance: boolean = false

  constructor(private router: Router, private claveunicaService: ClaveunicaLoginService, private nexteoService: NexteoService) {
    this.watcher()
    if (!this.instance) {
      this.instance = true
      setInterval(() => {
        this.watcher()
      }, this.timeReviewSession * 1000)
    }
  }

  watcher() {
    this.sessionData = this.getSessionStorage()
    const localSession = this.getSessionStorage()
    this.sessionSubject.next(this.sessionData)

    localSession.logged ? this.validSession(this.sessionData) : this.invalidSession()
  }

  async validSession(sessionData: SessionData) {
    // Si la sesión esta iniciada, no puede estar en vistas de login
    if (this.validateIsPathname(['/auth/callback', '/auth/login'])) {
      this.router.navigate(['/'])
    }

    sessionStorage.setItem('refresh', 'false')
    if (sessionData.data.time && moment().diff(sessionData.data.time, 'minutes') > 55) {
      sessionStorage.setItem('refresh', 'true')
      ;(await this.claveunicaService.refresh(sessionStorage.getItem('token') || '')).subscribe({
        next: (response: any) => {
          this.userLogged = { ...this.sessionData.data }
          if (!!this.userLogged && !!this.sessionData.data) {
            this.userLogged = { ...this.sessionData.data }
            this.userLogged.token.token_clave_unica = response.token_clave_unica
            this.userLogged.time = moment()
            this.sessionData.data = this.userLogged
            console.log(response.token_clave_unica)
            sessionStorage.setItem('token', response.token_clave_unica)
            sessionStorage.setItem('refresh', 'false')
            this.setSessionState(this.sessionData)
            this.sessionSubject.next(this.sessionData)
          }
        },
        error: (error: any) => {
          console.error('Error while refresh access token:', error)
          this.router.navigate(['/auth/login'])
          this.setSessionState(emptyUser)
        },
      })
    }
  }

  async onRefreshService() {
    try {
      const response = await new Promise<any>(async (resolve, reject) => {
        ;(await this.claveunicaService.refresh(sessionStorage.getItem('token') || '')).subscribe({
          next: (response: any) => {
            resolve(response)
          },
          error: (error: any) => {
            reject(error)
          },
        })
      })

      this.userLogged = { ...this.sessionData.data }
      const localSession = this.getSessionStorage()
      if (!!this.userLogged && !!this.sessionData.data) {
        this.userLogged.token.token_clave_unica = response.token_clave_unica
        console.log(response.token_clave_unica)
        sessionStorage.setItem('token', response.token_clave_unica)
        this.userLogged.time = moment()
        this.sessionData.data = this.userLogged
        sessionStorage.setItem('refresh', 'false')
        this.setSessionState(this.sessionData)
        this.sessionSubject.next(this.sessionData)
      }
    } catch (error) {
      console.error('Error while refresh access token:', error)
      this.router.navigate(['/auth/login'])
      this.setSessionState(emptyUser)
    }
  }

  unauthorizedLogout(error: any) {
    if (error.status === 401 || error.status === 0) {
      this.router.navigate(['/auth/login'])
      this.setSessionState(emptyUser)
    }
  }

  invalidSession() {
    this.getAccessCode()

    // Sin sesión y en una ruta invalida, redirige al LOGIN
    if (!this.validateIsPathname(['/auth/callback', '/auth/logout', '/auth/login', '/auth/error'])) {
      this.router.navigate(['/auth/login'])
      this.setSessionState(emptyUser)
    }

    // El access code o state code no son validos, redirige al LOGIN
    if (this.validateIsPathname(['/auth/callback']) && (this.accessCode === '' || this.accessState === '')) {
      this.router.navigate(['/auth/login']) // PENDIENTE: AQUI DEBE ENVIAR A /AUTH/ERROR
      this.setSessionState(emptyUser)
      throw 'ERROR: CODE o STATE no se han procesado correctamente'
    }

    // Si hay un access code y state code inicia el proceso de login
    if (this.validateIsPathname(['/auth/callback']) && this.accessCode !== '' && this.accessState !== '') {
      this.getAccessToken()
    }
  }

  async getAccessToken() {
    ;(await this.claveunicaService.login(this.accessCode, this.accessState)).subscribe({
      next: (response: any) => {
        const userResponse: UserLoggedResponse = response
        const { body, token } = userResponse
        const { examiner_municipalities } = body

        sessionStorage.setItem('token', token.token_clave_unica || '')

        this.userLogged = {
          token,
          run: body.run,
          dv: body.dv,
          name: body.name,
          last_name: body.last_name,
          photo: body.photo,
          municipalities: examiner_municipalities,
          time: moment(),
        }

        if (examiner_municipalities.length === 1) {
          const firstMunicipality = examiner_municipalities[0]
          this.userLogged.id_municipality = firstMunicipality.id_municipality
          this.userLogged.name_municipality = firstMunicipality.name_municipality
          this.userLogged.role = firstMunicipality.role
          this.userLogged.role_permissions = firstMunicipality.role_permissions
        }

        this.sessionData.logged = true
        this.sessionData.data = this.userLogged
        this.setSessionState(this.sessionData)
        this.sessionSubject.next(this.sessionData)

        sessionStorage.setItem('refresh', 'false')

        if (this.userLogged.id_municipality != null) {
          this.router.navigate(['/applicant'])
        } else {
          this.router.navigate(['/auth/municipality-selection'])
        }
      },
      error: (error: any) => {
        console.error('Error while getting access token:', error)
      },
    })
  }

  getAccessCode(): void {
    const params = new URLSearchParams(window.location.search)
    this.accessCode = params.getAll('code')[0] || ''
    this.accessState = params.getAll('state')[0] || ''
  }

  validateIsPathname(paths: string[]): boolean {
    const pathname = window.location.pathname
    return paths.some((path: string) => path == pathname)
  }

  static loginWithSql(user: object) {
    const state = {
      logged: true,
      data: { ...user },
    }
    window.sessionStorage.setItem('session', JSON.stringify(state))
  }

  claveUnicaLogout() {
    this.claveunicaService.claveUnicaLogout()
  }

  static logout() {
    window.sessionStorage.setItem('session', JSON.stringify(emptyUser))
  }

  getSessionStorage(): SessionData {
    const sessionData = window.sessionStorage.getItem('session')
    return typeof sessionData === 'string' ? JSON.parse(sessionData) : emptyUser
  }

  setSessionState(state: SessionData): void {
    window.sessionStorage.setItem('session', JSON.stringify(state))
  }
}
